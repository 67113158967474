// BlogList.js
import React from 'react';
import { useInView } from 'react-intersection-observer';
import PageTitle from '../../components/page-title/PageTitle';
import BannerComponent from '../../components/banner/BannerComponent';
import parse from 'html-react-parser';




function Publications({ posts }) {

  const [ref, inView] = useInView({
    // Trigger just once
    triggerOnce: true,
  });

  return (
    <>

      <PageTitle title="Publications" />

      <div>
        <BannerComponent title="Publications" image="anton-maksimov-5642-su-wrkNQmhmdvY-unsplash.jpg" />

        <div ref={ref} className={`bg-slate-100 py-20 my-10 md:py-44 md:my-10 ${inView ? 'animate-fade-in-up' : ''}`} >
          <div className="w-4/5 md:w-2/3 mx-auto items-center">
            <div className="block md:grid grid-cols-2 gap-5 mt-10">
              {posts.map(post => (
                <div key={post.id} className="mb-4 p-10 bg-gray-800 flex flex-col justify-between space-y-5">
                  <div>
                    <h3 className="text-1xl text-white font-bold mb-2">{post.title}</h3>
                  </div>
                  <div>
                    <p className="text-white line-clamp-3 text-justify">{parse(post.excerpt)}</p>
                  </div>
                  <div>
                    <a href={`/publications/${post.id}`} className="text-blue-500 hover:underline">Read more</a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Publications;
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';

import HeaderComponent from './components/header/HeaderComponent';
import FooterComponent from './components/footer/FooterComponent';

import Home from './pages/home/Home';
import NotFound from './pages/not-found/NotFound';
import GetInvolved from './pages/get-involved/GetInvolved';


import ResearcherMap from './pages/researcher-map/ResearcherMap';
import VulnerabilityList from './pages/vulnerability-list/VulnerabilityList';


import CloudLayer from './pages/research-areas/cloud-layer/CloudLayer';
import MiddlewareLayer from './pages/research-areas/middleware-layer/middleware-layer';
import SensorsLayer from './pages/research-areas/sensors-layer/sensors-layer';
import Publications from './pages/publications/Publications';
import PublicationsPost from './pages/publications-post/PublicationsPost';


function App() {


  const [posts, setPosts] = useState([]);
  useEffect(() => {
    fetch('https://blog.idcd.org.uk/wp-json/wp/v2/posts?_embed')
      .then(response => response.json())
      .then(data => {
        const formattedPosts = data.map(post => ({
          id: post.slug,
          title: post.title.rendered,
          excerpt: post.excerpt.rendered,
          content: post.content.rendered,
          image: post._embedded['wp:featuredmedia'] ? post._embedded['wp:featuredmedia'][0].source_url : null,
          author: post._embedded.author[0].name,
          date: post.date,
          avatar: post._embedded.author[0].avatar_urls['96'],
          biography: post._embedded.author[0].description,
        }));
        setPosts(formattedPosts);
      })
      .catch(error => console.error('Error:', error));
  }, []);


  return (

    <Router>

      <div className="App md:mx-10">

        <HeaderComponent />

        <Routes>

          {/* Basic Paths */}
          <Route path="/" element={<Home />} />
          <Route path="/get-involved" element={<GetInvolved />} />

          {/* Research Areas */}
          <Route path="/sensors-layer" element={<SensorsLayer />} />
          <Route path="/middleware-layer" element={<MiddlewareLayer />} />
          <Route path="/cloud-layer" element={<CloudLayer />} />

          {/* Quantative Research */}
          <Route path="/researcher-map" element={<ResearcherMap />} />
          <Route path="/vulnerability-database" element={<VulnerabilityList />} />

          {/* Publications */}
          <Route path="/publications" element={<Publications posts={posts} />} />
          <Route path="/publications/:postId" element={<PublicationsPost posts={posts} />} />

          {/* Not Found */}
          <Route path="*" element={<NotFound />} />

        </Routes>

        <FooterComponent />

      </div>

    </Router>
  );
}

export default App;
import React, { useState } from 'react';
import ArrowIcon from '../../svg/arrow-icon/ArrowIcon';

const SubmenuComponent = ({ title, links, isOpen }) => {

    const [isDropdownOpen, setDropdownOpen] = useState(false);

    return (
        <React.Fragment>
            {
                // Mobile view
                isOpen && (
                    <div className="relative">
                        <div className={`group justify-between p-5 bg-black ${isDropdownOpen ? 'bg-blue-700' : 'hover:bg-blue-600'} lg:hover:bg-transparent lg:bg-transparent flex items-center cursor-pointer select-none`} onClick={() => setDropdownOpen(!isDropdownOpen)}>
                            {title}
                            <div className={isDropdownOpen ? 'ml-auto transform transition-transform duration-500 rotate-180' : 'ml-1 transform transition-transform duration-500 group-hover:rotate-180'}>
                                <ArrowIcon />
                            </div>
                        </div>
                        {isDropdownOpen && (
                            <div>
                                {links.map((link, index) => (
                                    <a key={index} href={link.href} className="block p-5 bg-gray-900 hover:bg-blue-600 lg:hover:bg-transparent lg:bg-transparent">{link.text}</a>
                                ))}
                            </div>
                        )}
                    </div>
                )
            }

            {
                // Desktop view
                !isOpen && (
                    <div className="relative" onMouseEnter={() => setDropdownOpen(true)} onMouseLeave={() => setDropdownOpen(false)}>
                        <div className="group p-5 flex items-center cursor-pointer select-none">
                            {title}
                            <div className={isDropdownOpen ? 'ml-1 transform transition-transform duration-500 rotate-180' : 'ml-1 transform transition-transform duration-500 group-hover:rotate-180'}>
                                <ArrowIcon />
                            </div>
                        </div>
                        {isDropdownOpen && (
                            <div className="absolute left-0 w-48 shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                    {links.map((link, index) => (
                                        <a key={index} href={link.href} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">{link.text}</a>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                )
            }
        </React.Fragment>
    );
};

export default SubmenuComponent;
/**
 * 
 * Page Section Component
 * 
 * 
 */

import React from 'react';
import { useInView } from 'react-intersection-observer';


const PageSectionComponent = (props) => {

    const [ref, inView] = useInView({
        // Trigger just once
        triggerOnce: true,
    });

    return (
        <div ref={ref} className={`bg-slate-100 py-20 my-10 md:py-44 md:my-10 ${inView ? 'animate-fade-in-up' : ''}`} id={props.anchor}>
            <div className="w-4/5 md:w-2/3 mx-auto items-center">

                <div className="space-y-2">
                    <div className={`flex items-center`}>
                        <hr className="border-t-2 border-blue-600 w-5 mr-4" />
                        <h2 className="text-sm font-bold text-left text-blue-600 uppercase">{props.subtitle}</h2>
                    </div>
                    <div>
                        <h2 className="text-2xl font-bold text-left text-gray-800">{props.title}</h2>
                    </div>
                </div>

                <p className="mt-5">
                    {props.longtext.map((item, index) => (
                        <React.Fragment key={index}>
                            {item}
                            <br />
                            {index < props.longtext.length - 1 && <br />}
                        </React.Fragment>
                    ))}
                </p>

                {props.listItems &&
                    <ol className="list-decimal list-inside pl-5 space-y-4 pt-5">
                        {props.listItems.map((item, index) => (
                            <li key={index}>
                                <strong>{item.title}:</strong> {item.description}
                            </li>
                        ))}
                    </ol>
                }

            </div>
        </div>
    );
};

export default PageSectionComponent;
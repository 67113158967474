/**
 * 
 * Banner Component
 * 
 * 
 */

import React from 'react';

const BannerComponent = ({ title, subtitle, image }) => {

    return (
        <div className="bg-cover bg-center bg-no-repeat bg-gray-700 h-[50vh]" style={{ backgroundImage: `url('${image}')` }}>
            <div className=" bg-black bg-opacity-70 h-full flex items-center justify-center">
                <div className=" w-2/3 mx-auto items-center">
                    <h1 className="text-white text-3xl font-bold">{title}</h1>
                    <div className="pt-2">
                        <span className="text-white text-lg">{subtitle}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BannerComponent;
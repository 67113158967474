import React from 'react';
import { useInView } from 'react-intersection-observer';
import PageTitle from '../../components/page-title/PageTitle';


const NotFound = () => {


    const [ref, inView] = useInView({
        // Trigger just once
        triggerOnce: true,
    });

    return (

        <>
            {
                /* 
                 * 
                 * Page title.
                 * 
                */
            }
            <PageTitle title="404 Not Found" />


            <div ref={ref} className={`bg-slate-100 py-80 my-10 md:py-80 md:my-10 ${inView ? 'animate-fade-in-up' : ''}`}>
                <div className="w-4/5 md:w-2/3 mx-auto items-center">

                    <div className="space-y-2">
                        <div className={`flex items-center`}>
                            <hr className="border-t-2 border-blue-600 w-5 mr-4" />
                            <h2 className="text-sm font-bold text-left text-blue-600 uppercase">404</h2>
                        </div>
                        <div>
                            <h2 className="text-2xl font-bold text-left text-gray-800">Page Not Found</h2>
                        </div>
                        <div>
                            <p>The page you are looking for does not exist.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NotFound;

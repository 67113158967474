// VulnerabilityList.js
import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import SettingsIcon from '../../components/svg/settings-icon/SettingsIcon';
import SearchIcon from '../../components/svg/search-icon/SearchIcon';
import PageTitle from '../../components/page-title/PageTitle';


function VulnerabilityList() {

  /**
   * 
   * Transition effect
   * 
  */
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  /**
   * 
   * Detect screen size
   * 
  */
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 760);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 760);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /**
   * 
   * Dropdown filter trigger
   * 
  */
  const [filter, setFilter] = useState();
  // Make sure to reset all filters when it is closed.
  const triggerSetFilter = (value) => {
    if (value === false) {
      setcountryId(0);
      setManufacturerId(0);
    }
    setFilter(value);
  }

  /**
   * 
   * Row click and show more information
   * 
   */
  const [selectedId, setSelectedId] = useState(1);
  // Handle row click
  const handleRowClick = (id) => {
    setSelectedId(selectedId === id ? null : id);
  };

  /**
   * 
   * Set search state => Dropdown
   * 
  */
  const [search, setSearch] = useState('');














  // Set country state => Dropdown
  const [lastCountryId, setLastCountryId] = useState(0);
  const [countryId, setcountryId] = useState(0);
  const triggerSetSelectCountry = (value) => {
    setManufacturerId(0);
    // current country id
    setcountryId(value);
  }

  // Set manufacturer state => Dropdown
  const [lastManufacturerId, setLastManufacturerId] = useState(0);
  const [manufacturerId, setManufacturerId] = useState(0);
  const triggerSetSelectManufacturer = (value) => {
    // current manufacturer id
    setManufacturerId(value);
  }








  // Available countries on load.
  const [availableCountries, setAvailableCountries] = useState([]);

  // Available manufacturers 
  const [availableManufacturers, setAvailableManufacturers] = useState([]);

  // Set the loaded data everyclick.
  const [content, setContent] = useState([]);











  // Fetch data
  useEffect(() => {
    const fetchData = async () => {

      /**
       * 
       * 
       * CHANGE IT BEFORE PRODUCTION
       * 
       * -> Base Endpoint 
       * 
       * 
      */
      let BASE_ENDPOINT = 'https://api.idcd.org.uk/api/v1/vulnerabilities?';

      /**
       * 
       * 
       * 
       * Init query counter to know about & and ?
       * 
       * 
       */
      let searchCounter = 0;

      /**
       * 
       * 
       * Attribute Filters
       * 
       * Ignore null being set to 0 as it can still be a valid search.
       * 
       * If manufacturerId is not null or 0 then only fetch manufacturers with base point.
       * 
       * 
      */
      if (search !== '') {
        BASE_ENDPOINT += `search=${search}`;

        // Add to counter
        searchCounter++;
      }

      if (countryId !== null && countryId !== 0) {

        // If a search query has already been added.
        if (searchCounter > 0) {
          BASE_ENDPOINT += '&'
        };

        BASE_ENDPOINT += `countries_id=${countryId}`;

        // Add to counter
        searchCounter++;
      }


      if (manufacturerId !== null && manufacturerId !== 0) {

        // If a search query has already been added.
        if (searchCounter > 0) {
          BASE_ENDPOINT += '&'
        };

        BASE_ENDPOINT += `manufacturers_id=${manufacturerId}`;

        // Add to counter
        searchCounter++;
      }


      /**
       * 
       * 
       * 
       * Exec "Fetch" data to get it from API
       * 
       * 
       */
      const response = await fetch(BASE_ENDPOINT);


      /**
       * 
       * Parse JSON data.
       * 
      */
      const data = await response.json();


      /**
       * 
       * Save state of reponse.
       * 
      */
      setContent(data.data);


      /**
       * 
       * Save available countries to state.
       * 
       * "availableCountries.length === 0" means that it is the first time the page is loaded.
       * 
       */
      if (availableCountries.length === 0) {
        // for loop to get the countries.
        for (let i = 0; i < data.data.length; i++) {
          // define variables
          let id = data.data[i].id;
          let name = data.data[i].name;
          // add to available countries
          availableCountries.push({ id, name });
        }
      }

      /**
       * 
       * If a country has been selected.
       * 
       * 0 is the default value so if it is not 0 then a country has been selected.
       * 
       */
      if (countryId != null && countryId !== 0) {

        /**
         * 
         * Always set last country_id
         * 
        */
        setLastCountryId(countryId);

        /**
         * 
         * If the last country id is not the same as the current country id then 
         * user has selected a new country and it is not the same as the last one.
         * 
         */
        if (lastCountryId !== countryId) {
          // Reset the manufacturers
          setAvailableManufacturers(data.data);
        }

      }


    };

    fetchData();

  }, [countryId, availableCountries, lastManufacturerId, lastCountryId, manufacturerId, search]);



  return (

    <>
      
      <PageTitle title="Vulnerability Database" />

      <div>
        <div ref={ref} className={`my-10 ${inView ? 'animate-fade-in-up' : ''}`} >
          <div className="mx-auto items-center">

            <div className="p-6 bg-slate-800 shadow-lg shadow-gray-400 flex flex-row">

              <div className="group font-bold cursor-pointer select-none items-center flex w-1/2 text-white hover:text-blue-600 fill-white hover:fill-blue-600" onClick={() => triggerSetFilter(!filter)}>
                Filters
                <div className="ml-1 transform transition-transform duration-500 group-hover:rotate-180">
                  <SettingsIcon />
                </div>
              </div>

              <div className="group justify-end content-center font-bold cursor-pointer select-none flex w-1/2 text-white hover:text-blue-600 fill-white hover:fill-blue-600">
                <div className="p-2 mr-1 transform transition-transform duration-500 group-hover:rotate-180">
                  <SearchIcon />
                </div>
                <input
                  className="bg-transparent border border-l-0 border-r-0 border-t-0 border-b text-white p-2 font-bold"
                  type="text"
                  placeholder="Search vulnerabilities..."
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                />
              </div>


            </div>

            {filter && (
              <div className="bg-gray-800 py-6 text-center content-center text-white font-bold">
                <div className="block space-y-5 md:space-y-0 md:flex md:items-center justify-left space-x-5 px-5">


                  <select
                    className="bg-transparent border border-l-0 border-r-0 border-t-0 border-b text-white p-2 font-bold w-1/4 md:w-full"
                    name="page"
                    id="page"
                    onChange={e => triggerSetSelectCountry(e.target.value)}
                  >
                    <option value="0">Country</option>
                    {availableCountries.map(post =>
                      <option key={post.id} value={post.id}>
                        {post.name}
                      </option>
                    )}
                  </select>


                  <select
                    className="bg-transparent border border-l-0 border-r-0 border-t-0 border-b text-white p-2 font-bold w-1/4 md:w-full"
                    name="page"
                    id="page"
                    onChange={e => triggerSetSelectManufacturer(e.target.value)}
                  >
                    <option value="0">Manufacturer</option>
                    {availableManufacturers.map(post =>
                      post.manufacturers.map(manufacturer => (
                        <option key={manufacturer.id} value={manufacturer.id}>
                          {manufacturer.name}
                        </option>
                      ))
                    )}
                  </select>


                  <select className="bg-transparent border border-l-0 border-r-0 border-t-0 border-b text-white p-2 font-bold w-1/4 md:w-full">
                    <option value="0">Vulnerability Type</option>
                    {availableManufacturers.map(post =>
                      post.manufacturers.map(manufacturer => (
                        manufacturer.vulnerabilities.map(vulnerability => (
                          vulnerability.vulnerability_types.map(type => (
                            <option key={type.id} value={type.id}>
                              {type.name}
                            </option>
                          ))
                        ))
                      ))
                    )}
                  </select>


                  <select className="bg-transparent border border-l-0 border-r-0 border-t-0 border-b text-white p-2 font-bold w-1/4 md:w-full">
                    <option value="0">Sort By</option>
                    <option value="1">Report Date (Oldest)</option>
                    <option value="1">Report Date (Newest)</option>
                    <option value="2">Manufacturer (A-Z)</option>
                    <option value="3">Manufacturer (Z-A)</option>
                  </select>



                </div>
              </div>
            )}


            <div className="overflow-y-auto h-[575px] ">
              <table className="hyphens-auto w-full text-left" cellPadding={`${isMobile ? '10' : '15'}`}>

                <thead>
                  <tr className="shadow-lg shadow-gray-400 sticky top-0 bg-gray-700 text-white h-16">
                    <th>ID</th>
                    <th className="w-auto md:w-2/12">Name</th>
                    <th>Manufacturer</th>
                    <th>Summary</th>
                    <th className="w-auto md:w-2/12">Date</th>
                  </tr>
                </thead>

                <tbody>
                  {content.map(post =>
                    post.manufacturers.map(manufacturer =>
                      manufacturer.vulnerabilities.map(vulnerability => (
                        <React.Fragment key={vulnerability.id}>
                          <tr className={` h-24 select-none cursor-pointer transition-colors duration-300 ease-in-out hover:bg-gray-500 hover:text-white ${selectedId === vulnerability.id ? 'bg-gray-500 text-white' : ''}`} key={vulnerability.id} onClick={() => handleRowClick(vulnerability.id)}>
                            <td>{vulnerability.id}</td>
                            <td>{vulnerability.name}</td>
                            <td>{manufacturer.name}</td>
                            <td>{vulnerability.filtered_description}</td>
                            <td>{vulnerability.date}</td>
                          </tr>
                          {selectedId === vulnerability.id && (
                            <tr>
                              <td colSpan="5" className="px-4 space-y-5 py-20 bg-gray-200">

                                {
                                  vulnerability.vulnerability_types && (
                                    <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row">
                                      {
                                        vulnerability.vulnerability_types.map(type => (
                                          <span key={type.id} className="bg-blue-700 text-white px-2 py-1 rounded-md mr-2 select-none cursor-pointer shadow-md shadow-slate-500">{type.name}</span>
                                        ))
                                      }
                                    </div>
                                  )
                                }

                                <div className="px-1">

                                  <h3 className="font-bold py-2">Description</h3>


                                  <p>{vulnerability.description}</p>

                                  <br />

                                  {vulnerability.vulnerability_links.map(link => (
                                    <div key={link.id}>
                                      <a className="text-blue-700 font-bold" href={link.link} target="_blank" rel="noopener noreferrer">Redirect to CVE</a>
                                    </div>
                                  ))}

                                </div>

                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))
                    )
                  )}
                </tbody>

              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VulnerabilityList;
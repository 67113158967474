/**
 * 
 * 
 * Contact Form Component
 * 
 * 
 */

import React from 'react';
import { useInView } from 'react-intersection-observer';


const ContactFormComponent = () => {

    const [ref, inView] = useInView({
        // Trigger just once
        triggerOnce: true,
    });

    return (

        <div ref={ref} className={`bg-slate-100 py-20 my-10 md:py-44 md:my-10 ${inView ? 'animate-fade-in-up' : ''}`}>
            <div className=" w-2/3 mx-auto items-center">
                
                <div className={`flex items-center`}>
                    <hr className="border-t-2 border-gray-300 w-10 mr-4"/>
                    <h2 className="text-2xl font-bold text-left text-gray-800">Contact Us</h2>
                </div>
                <p className="mt-4 text-justify">
                    If you are interested in collaborating with the IDCD or learning more about our initiatives, please contact us using the form below. We look forward to hearing from you and working together to enhance the cybersecurity of diabetes care.
                </p>

                <form className="mt-8">

                    <div className="grid grid-cols-1 gap-6">
                        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                            <label className="block">
                                <span className="text-gray-700 font-bold">Name</span>
                                <input type="text" className="form-input bg-transparent mt-1 block w-full p-4 border-solid border-b-2 border-y-slate-800" placeholder="Enter your name" />
                            </label>
                            <label className="block">
                                <span className="text-gray-700 font-bold">Email</span>
                                <input type="email" className="form-input bg-transparent mt-1 block w-full p-4 border-solid border-b-2 border-y-slate-800" placeholder="Enter your email" />
                            </label>
                        </div>
                        <label className="block">
                            <span className="text-gray-700 font-bold">Message</span>
                            <textarea className="form-textarea bg-transparent mt-1 block w-full p-4 border-solid border-b-2 border-y-slate-800" rows="3" placeholder="Enter your message"></textarea>
                        </label>
                    </div>

                    <div className="mt-6">
                        <button type="submit" className="h-14 w-full bg-slate-600 text-white uppercase font-bold text-sm">Submit</button>
                    </div>

                </form>
            </div>
        </div>

    );
}

export default ContactFormComponent;
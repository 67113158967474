/**
 * 
 * Get Involved Page
 * 
 * Form
 * 
 */

import React from 'react';
import BannerComponent from '../../components/banner/BannerComponent';
import PageSectionComponent from '../../components/page-section-component/PageSectionComponent';
import ContactFormComponent from '../../components/contact-form/ContactFormComponent';
import PageTitle from '../../components/page-title/PageTitle';


const GetInvolved = () => {


    return (

        <>
            {
                /* 
                 * 
                 * Page title.
                 * 
                */
            }
            <PageTitle title="Get Involved" />

            <div>

                <BannerComponent title="Get Involved" subtitle="Make a difference" image="bartosz-kwitkowski-SJ5TmRRSM1U-unsplash.jpg" />

                <PageSectionComponent title="Get Involved" subtitle="Contact Us" longtext={["The Initiative for Diabetes Cyber Defense (IDCD) welcomes individuals and organizations interested in contributing to our mission of protecting the digital well-being of patients living with diabetes. Whether you are a researcher, healthcare professional, technology expert, or advocate, there are various ways to get involved with the IDCD and support our efforts to advance cybersecurity in diabetes care."]} anchor="get-involved" />

                <ContactFormComponent />

            </div>

        </>
    );
};

export default GetInvolved;
/**
 * 
 * HeaderButtonComponent.js
 * 
 * 
 */

import React from 'react';

const HeaderButtonComponent = ({ text, link }) => {
    
    const handleClick = () => {
        window.location.href = link;
    };

    return (
        <button onClick={handleClick} className="h-16 px-8 bg-blue-700 text-white uppercase font-bold text-sm">
            {text}
        </button>
    );
}

export default HeaderButtonComponent;
/**
 * 
 * Footer component
 * 
 * 
 */

import React from 'react';

const FooterComponent = () => {

    // current year
    const currentYear = new Date().getFullYear();

    return (
        <footer className="bg-gray-800 text-white">
            {/*
            <div className="py-10 flex justify-evenly items-center bg-gray-100" id="our-affiliates">
                <img src="./logo_imperial_college_london.png" alt="Affiliate Logo" className="h-14 w-auto" />
            </div>
            */}

            <div className="container mx-auto p-8">
                <div className="flex flex-col sm:flex-row justify-between items-center">
                    
                    <div className="order-last sm:order-first mt-5 sm:mt-0">
                        <p>&copy; {currentYear} Initiative for Diabetes Cyber Defense</p>
                    </div>

                    <div className="space-x-4 order-first sm:order-last">
                        <a href=" ">Privacy Policy</a>
                        <a href=" ">Terms of Service</a>
                    </div>
                
                </div>
            </div>

        </footer>
    );

}

export default FooterComponent;
/**
 * 
 * Homepage
 * 
 * 
 */

import React from 'react';
import BannerComponent from '../../components/banner/BannerComponent';
import PageSectionComponent from '../../components/page-section-component/PageSectionComponent';
import PageSectionComponentBox from '../../components/page-section-component-box/PageSectionComponentBox';
import PageTitle from '../../components/page-title/PageTitle';


const Home = () => {

    return (

        <>
            {
                /* 
                 * 
                 * Page title.
                 * 
                */
            }
            <PageTitle title="Home" />

            <div>

                <BannerComponent title="Scientia pro tutela vulnerabilium." subtitle="Welcome to the Initiative for Diabetes Cyber Defense" image="flyd-OLRXnzXFBjo-unsplash.jpg" />

                <PageSectionComponent
                    subtitle="Our Approach to Cyber Defense"
                    title="Our Mission Statement"
                    longtext={[
                        "The Initiative for Diabetes Cyber Defense (IDCD) is an organization dedicated to protecting the digital well-being of patients living with diabetes. Established in response to the growing intersection of diabetes management and cybersecurity concerns, the IDCD is committed to advancing research, education, and advocacy initiatives to mitigate the risks posed by cyber threats in diabetes care.",
                        "In alignment with the United Nations Sustainable Development Goals (UNSDGs), the IDCD strives to contribute to Goal 3: Good Health and Well-being, by ensuring that individuals living with diabetes have access to secure and reliable digital health solutions that enhance their health outcomes while safeguarding their personal information. Additionally, the IDCD's efforts support Goal 9: Industry, Innovation, and Infrastructure, by fostering innovation in cybersecurity solutions for diabetes care and promoting the development of resilient digital infrastructure within the healthcare sector."
                    ]}
                    anchor="mission-statement"
                    button="Learn More"
                />


                {/*
            <div className={`bg-gray-800 py-20 my-10 md:py-44 md:my-10`}>
                <div className="w-4/5 md:w-2/3 mx-auto items-center">
                    <div className="space-y-2">
                        <img
                            src="/closed-loop-system.png"
                            alt="Cloud Layer"
                            width={`full`}
                            height={`full`}
                        />
                    </div>
                </div>
            </div>
            */}


                <PageSectionComponentBox
                    subtitle="Our Approach to Cyber Defense"

                    title="Our Approach to Cyber Defense"

                    longtext={[
                        "Our current research at the Initiative for Diabetes Cyber Defense (IDCD) focuses on several key areas essential to addressing cybersecurity challenges in diabetes care. By advancing knowledge in these areas, our research endeavors aim to drive meaningful progress in safeguarding the digital well-being of patients living with diabetes and promoting a secure and resilient ecosystem for diabetes care delivery."
                    ]}

                    box1={{
                        'Title': 'Sensor Nodes Layer',
                        'description': 'We are conducting in-depth analyses of the evolving cybersecurity threat landscape specific to diabetes management, including the identification of emerging cyber threats, vulnerabilities in digital health technologies, and potential attack vectors.',
                        'link': '/sensor-nodes-layer'
                    }}

                    box2={{
                        'Title': 'Middleware Layer',
                        'description': 'Our research aims to develop comprehensive risk assessment frameworks and mitigation strategies tailored to the unique needs and complexities of diabetes care settings, considering factors such as patient data security, medical device vulnerabilities, and regulatory compliance requirements.',
                        'link': '/middleware-layer'
                    }}

                    box3={{
                        'Title': 'Cloud Layer',
                        'description': 'Our research aims to develop comprehensive risk assessment frameworks and mitigation strategies tailored to the unique needs and complexities of diabetes care settings, considering factors such as patient data security, medical device vulnerabilities, and regulatory compliance requirements.',
                        'link': '/cloud-layer'
                    }}

                />

            </div>
        </>
    );
};

export default Home;